.navbar {
    display: flex;
    border-bottom: 3px solid #193570;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.logo-button {
    background: none;
    border: none;
    cursor: pointer; 
}

.img {
    max-width: 130px; 
    height: auto;
    padding: 0; 
}

.logo{
    max-width: 70px;
    height: auto;
    padding: 0;
}

.navbar h1 {
    color: #193570;
    margin: 0;
}

.navbar button {
    background: transparent;
    color: #193570;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    border: none;
}

.navbar button:hover {
    opacity: 0.8;
}

/* Responsividade para telas menores que 768px */
@media (max-width: 768px) {
    .navbar {
        padding: 15px;
    }

    .navbar h1 {
        text-align: center;
    }

    .img {
        max-width: 100px; 
    }

    .navbar button {
        font-size: 24px; 
        margin: 5px 0; 
    }
}

/* Responsividade para telas menores que 480px */
@media (max-width: 480px) {
    .navbar h1 {
        font-size: 24px; 
    }

    .navbar {
        padding: 10px;
    }

    .img {
        max-width: 80px; 
    }

    .navbar button {
        font-size: 20px; 
    }
}
