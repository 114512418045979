.results-grid {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 1em;
  padding: 1em;
  flex-wrap: wrap;
}

/* Responsividade para telas menores que 768px */
@media (max-width: 768px) {
  .results-grid {
    justify-content: center;
  }

  .result-details {
    min-width: 100%;
    max-width: none;
  }
}

/* Responsividade para telas menores que 480px */
@media (max-width: 480px) {
  .container-result-details h1 {
    font-size: 1.5em;
  }

  .container-result-details h2 {
    font-size: 1.2em;
  }

  .container-result-details button {
    font-size: 1em;
    padding: 0.5em 1em;
  }

  .result-details h1 {
    font-size: 1.2em;
  }
}

.group-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-result-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 90vh;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  text-align: center;
}

.container-result-details h1 {
  margin: 0;
  font-size: 2em;
  color: #193570;
  text-align: center;
}

.container-result-details h2 {
  margin: 0.5em 0;
  font-size: 1.5em;
  color: #333;
}

.container-result-details button {
  margin-top: 20px;
  padding: 0.75em 1.5em;
  border: 2px solid #193570;
  border-radius: 5px;
  background-color: #193570;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 1.2em;
  flex: 0 0 auto;
  justify-content: space-between;
}

.container-result-details button:hover {
  background-color: #1a4a90;
  transform: scale(1.05);
}

.result-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #193570;
  border-radius: 5px;
  background-color: white;
  margin: 0.5em;
  padding: 1em;
  min-width: 150px;
  max-width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  height: 100%;
}

.result-details h1 {
  font-size: 1.5em;
  color: #193570;
}

.icon {
  font-size: 50px;
  color: #193570;
}

.result-details h3 {
  margin: 0.5em 0;
  color: #555;
  text-align: left;
  width: 100%;
}

.button-popup {
  color: #193570 !important;
  background-color: transparent !important;
  padding: 5px !important;
  margin: 0 !important;
  margin-top: 5px !important;
  display: flex;
  justify-content: flex-start;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #193570;
  color: #fff;
  width: 40%;
  padding: 10px;
  border-radius: 10px;
}

.popup-content .text-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
  gap: 20px;
}

.popup-content button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: white;
  color: #193570;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #f1f1f1;
}

.popup-content h1 {
  font-size: 1.5em;
  margin: 0;
  padding: 5px;
}

.fullscreen-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background-color: transparent !important;
  color: #193579 !important;
  border: none !important;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0 !important;
}

.in-fullscreen-button {
  position: absolute;
  bottom: -10px;
  right: 50%;
  transform: translateX(50%);
  z-index: 10;
  padding: 10px 20px;
  background-color: transparent !important;
  color: #193579;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}

.full-screen graph {
  max-width: 60%;
  max-height: 60%;
  object-fit: contain;
  display: block;
  margin: auto;
}

@media (max-width: 768px) {
  .popup-content {
    position: relative;
    width: 80%;
  }
  .full-screen img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    display: block;
    margin: auto;
  }
}

@media (max-width: 480px) {
  .popup-content {
    width: 95%;
    top: 5px;
    padding: 15px;
    padding-bottom: 0 !important;
  }
  .full-screen img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    display: block;
    margin: auto;
  }
}
