.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: start;
  min-height: 80vh;
  font-size: large;
  padding-top: 20px;
}

.container-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: 80vh;
  font-size: large;
  padding-top: 20px;
}

.container h1 {
  margin: 0;
  padding-bottom: 5px;
}

.container h3 {
  margin: 0;
  margin-bottom: 20px;
}

.container form {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.container input,
.select,
.container select {
  width: 100%;
  padding: 10px 0;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #193570 !important;
  border-radius: 0;
  outline: none;
  color: #193570 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  text-align: left !important;
  padding-left: 0 !important;
}

.select {
  display: flex;
  justify-content: space-between !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
  padding-bottom: 0 !important ;
}

.container input::placeholder {
  color: #193570;
}

.select {
  appearance: none;
  background-color: transparent !important;
}

.container button {
  background-color: #193570;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.container button:hover {
  background-color: #0056b3;
}

/* Responsividade para telas menores que 768px */
@media (max-width: 768px) {
  .container {
    font-size: medium;
    padding: 20px;
  }

  .container form {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .container input,
  .container select {
    padding: 8px 0;
  }
}

/* Responsividade para telas menores que 480px */
@media (max-width: 480px) {
  .container {
    font-size: large;
    padding: 15px;
  }

  .container h2 {
    font-size: 24px;
  }

  .container h3 {
    font-size: 20px;
  }

  .container form {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.return-button {
  background: none !important;
  color: inherit !important;
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  cursor: pointer;
  transition: color 0.3s ease;
  font-size: 25px;
  margin: 5px;
}

.return-button:hover {
  color: #193570 !important;
}

.tablist {
  display: inline-flex !important;
  gap: 10px !important;
  justify-content: center !important;
  margin-bottom: 20px !important;
  padding: 10px !important;
  background-color: #f9f9f9 !important;
  border-radius: 8px !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) !important;
  margin-top: 20px;
}

.tablist button {
  padding: 10px 20px !important;
  border: none !important;
  background-color: transparent !important;
  cursor: pointer !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: #808080 !important;
  border-bottom: 2px solid transparent !important;
  transition: all 0.3s ease !important;
}

.tablist button[data-state="active"] {
  border-bottom: 2px solid #193579 !important;
  color: #193579 !important;
}

.tablist button:hover {
  color: #193579 !important;
}

.search input {
  width: 50% !important;
  border: 1px solid #193570 !important;
  border-right: none !important;
  border-radius: 5px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.search {
  display: flex;
  flex-direction: column !important;
}

.search input::placeholder {
  text-align: center;
}

.search p {
  margin: 0.5em;
}
