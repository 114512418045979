.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.loading-screen p {
  margin-top: 20px;
  color: #193570;
  font-size: 18px;
}
