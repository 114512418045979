.admin-container {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    align-items: center; 
    justify-content: center; 
    padding: 20px;
    background-color: #f4f4f9; 
}

.admin-container h1 {
    border-bottom: 1px solid #193570;
    border-top: 1px solid #193570;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.5em; 
}

.admin-user, .regular-user {
    width: 100%;
    min-width: 150px;
    max-width: 400px; 
    border: 1px solid #193570;
    border-radius: 5px;
    margin: 10px auto; 
    padding: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
    background-color: #fff;
}

.divisor {
    display: flex;
    align-items: center;
    justify-content: center; 
    gap: 10px;
    margin: 20px 0;
    padding: 0 10px;
}

.change-page {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;
}

.change-page button {
    padding: 10px 20px;
    border: none;
    background-color: #193570;
    color: white;
    font-size: 1.2em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.change-page button:hover {
    background-color: #162f5e;
}

.users-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px; 
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 10px;
}

.regular-user {
    min-width: 150px;
    max-width: 300px;
    margin: 0;
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Responsividade */

@media (max-width: 1200px) {
    .users-grid {
        grid-template-columns: repeat(2, 1fr); 
    }

    .divisor {
        gap: 2em; 
    }
}

@media (max-width: 768px) {
    .users-grid {
        grid-template-columns: 1fr; 
    }

    .admin-user, .regular-user {
        max-width: 90%; 
    }

    .divisor {
        flex-direction: column; 
        gap: 1em; 
    }
}

@media (max-width: 480px) {
    .admin-container h1 {
        font-size: 1.2em; 
    }

    .change-page button {
        font-size: 1em; 
        padding: 8px 16px;
    }

    .users-grid {
        gap: 10px; 
    }
}
