.footer {
    border-top: 3px solid #193570;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #193570;
    font-size: 10px;
    padding: 15px; 
}

/* Responsividade para telas menores que 768px */
@media (max-width: 768px) {
    .footer {
        font-size: 10px; 
        padding: 20px; 
    }
}

/* Responsividade para telas menores que 480px */
@media (max-width: 480px) {
    .footer {
        font-size: 12px;
        padding: 10px;
        flex-direction: column;
        text-align: center; 
    }
}
