.message {
  border-radius: 5px;
  padding: 3px 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
}

.message.error {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}

.message.success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3eccb !important;
}

.message.information {
  color: #193579;
  background-color: #7d99dd;
  border-color: #a0b5e0;
}

.message.information::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #7d99dd;
}
