.login {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #9D9D9E, #6d739e, #49588C, #193580);
    color: white;
    text-align: center;
}

.form-container {
    background-color: rgba(255, 255, 255, 0.3);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.form {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    width: 100%;
    max-width: 400px; 
}

.form input {
    margin: 10px 0;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #fff;
    background-color: transparent;
    width: 90%;
    color: #fff;
    transition: background-color 0.3s;
}

.form input:focus {
    outline: none;
    background-color: transparent;
}

.form input::placeholder{
    color: #fff;
}

.form img {
    max-width: 100%;
    margin-bottom: 10px;
}

.form h1{
    margin: 2px;
}

.form button {
    background-color: #49588C;
    color: white; 
    padding: 10px; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer; 
    width: 90%; 
    margin-top: 10px;
}

.form button:hover {
    background-color: #6d739e;
}